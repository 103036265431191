@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.loginBodyPage {
  /* display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%; */
  /* background: url(https://img.playbook.com/IZSaaisbuPYRUSQYBZckzA61Px-1dJx2_5pGRp3N4dk/Z3M6Ly9wbGF5Ym9v/ay1hc3NldHMtcHVi/bGljLzRkZmUwYjQ2/LWRhNjAtNDQ2Yy1h/Y2UxLWM0ZTZkMGI3/NTdlMA); */
  /* background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px; */
}

.forgotpassword{
  background: transparent;
  box-shadow: none;
  border: none;
  color: #fff;
  margin-bottom: 7px;
}

.wrapper {
  width: 420px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, .2);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  color: #fff;
  border-radius: 10px;
  padding: 30px 40px;

}

.wrapper h1 {
  font-size: 36px;
  text-align: center;
}

.wrapper .input-box {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 30px 0;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, .2);
  border-radius: 40px;
  font-size: 16px;
  color: #fff;
  padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
  color: #fff;
}

.input-box i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;

}

.wrapper .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -15px 0 15px;
}

.remember-forgot label input {
  accent-color: #fff;
  margin-right: 3px;
}

.remember-forgot a {
  color: #fff;
  text-decoration: none;

}

.remember-forgot a:hover {
  text-decoration: underline;
}

.wrapper .btn {
  width: 100%;
  height: 45px;
  border-radius: 40px;
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.wrapper .register-link {
  text-align: center;
  font-size: 14.5px;
  margin: 20px 0 15px;
}

.register-link p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;

}

.register-link p a:hover {
  text-decoration: underline;
}

/* html{zoom: 100% !important;} */
html:has(.loginBodyPage){
  zoom: 100% !important;
}
.main-content:has(.loginBodyPage) {
  width: 100%; padding: 0px; margin: 0px;
}


.LoginPageContainer {
  height: 100vh;
  overflow: auto;
  font-family: Rubik;
}

.LoginPageInnerContainer {
  display: flex;
  min-height: 100%;
}

.LoginPageInnerContainer .ImageContianer {
  width: 30%;
  background-color: #e1dfec;
  min-height: 100%;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginPageInnerContainer .ImageContianer .GroupImage {
  width: 100%;
  display: block;
}

.LoginPageInnerContainer .LoginFormContainer {
  flex-grow: 2;
  background-color: white;
  min-height: 100%;
  padding: 5%;
  background: url(https://i.imgur.com/BKyjjFa.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.LoginPageInnerContainer .LoginFormContainer .LogoContainer .logo {
  height: auto;
  margin-bottom: 30px;
  padding: unset;
}

.LoginPageInnerContainer .LoginFormContainer .header {
  font-size: 32px;
  font-weight: 500;
}

.LoginPageInnerContainer .LoginFormContainer .subHeader {
  color: #9aa4ad;
  margin-top: 5px;
  margin-bottom: 40px;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer {
  color: #3f3f45;
  margin: 20px 0px;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer .label {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-right: 7px;
  margin-bottom: 10px;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer .label .labelIcon {
  width: 20px;
  margin-right: 10px;
  display: block;
}

.LoginPageInnerContainer .LoginFormContainer .inputContainer .input {
  display: block;
  width: calc(100% - 20px);
  font-size: 15px;
  padding: 10px;
  border: 1px solid #d6d7db;
  border-radius: 5px;
  margin-top: 5px;
  outline: 0px !important;
}

.LoginPageInnerContainer .LoginFormContainer .OptionsContainer {
  display: flex;
  justify-content: space-between;
}

.LoginFormContainer {
  display: flex;
  align-items: center;
}

.LoginFormInnerContainer {
  max-width: 500px;
}

.LoginPageInnerContainer .LoginFormContainer .checkbox {
  width: 15px;
  height: 15px;
  margin: 0px;
  display: block;
  border: 1px solid #d6d7db;
}

.LoginPageInnerContainer .LoginFormContainer .checkboxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LoginPageInnerContainer .LoginFormContainer .checkboxContainer label {
  display: block;
  padding: 0px 5px;
  color: #9aa4ad;
}

.LoginPageInnerContainer .LoginFormContainer .ForgotPasswordLink {
  color: #e7483b;
  text-decoration: none;
}

.LoginFormContainer .LoginFormInnerContainer .LoginButton {
  margin-top: 30px;
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
  background-color: #2e1f7a;
  border: 0px;
  outline: 0px;
  cursor: pointer;
}

.LoginFormContainer .LoginFormInnerContainer .LoginButton:active {
  background-color: #2e1f7a;
}
.subHeader b{color: #2e1f7a;}


@media only screen and (max-width: 1200px) {
  .LoginPageInnerContainer .ImageContianer {
      width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .LoginPageInnerContainer .ImageContianer {
      display: none;
  }

  .LoginFormContainer {
      justify-content: center;
  }
}
.LoginPageContainer::-webkit-scrollbar {
  width: 5px;
}

.LoginPageContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.LoginPageContainer::-webkit-scrollbar-thumb {
  background: #2e1f7a;
}

.LoginPageContainer::-webkit-scrollbar-thumb:hover {
  background: #4520ff;
}